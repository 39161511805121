<template>
  <div>
    <el-card class="x-card-title" header="基本信息">
      <div class="buttonbc">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="@/assets/back.png" alt />
        </el-button>
      </div>
      <el-form label-position="right" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属商城">
              <el-select
                style="width:100%"
                v-model="fundamental.shop_name"
                size="medium"
                placeholder="请选择商城"
                clearable
                disabled
              ></el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称">
              <el-input
                v-model="fundamental.client_name"
                size="medium"
                type="text"
                disabled
                placeholder="请输入客户名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="代理名称">
              <el-input
                v-model="fundamental.agent_name"
                size="medium"
                type="text"
                disabled
                placeholder="请输入代理名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系人名称">
              <el-input
                v-model="fundamental.link_name"
                size="medium"
                type="text"
                disabled
                placeholder="请输入联系人名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">
              <el-input
                v-model="fundamental.link_phone"
                size="medium"
                type="text"
                disabled
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销售人员">
              <el-input
                v-model="fundamental.sell_name"
                size="medium"
                type="text"
                disabled
                placeholder="请输入销售人员"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制单人名称">
              <el-input
                v-model="fundamental.make_order_name"
                size="medium"
                type="text"
                disabled
                placeholder="请输入制单人名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="客户地址" style="width:100%">
              <el-input
                v-model="fundamental.client_addr"
                size="medium"
                type="text"
                disabled
                placeholder="请输入客户地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="x-card-title" header="卡券信息">
      <el-form label-position="right" label-width="120px">
        <el-row v-if="type==='2'">
          <el-col :span="8">
            <el-form-item label="关联销售单号" prop="sn">
              <el-input
                v-model="fundamental.sn"
                size="medium"
                type="text"
                disabled
                placeholder="请输入关联销售单号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="卡券信息" prop="link_name"></el-form-item>
        <el-form-item label-width="0">
          <el-table
            :header-cell-style="{
            textAlign: 'center',
            backgroundColor: 'rgba(230, 236, 247, 1)',
          }"
            style="line-height:1"
            :data="cardTable"
            border
          >
            <el-table-column label="卡券名称" prop="card_name">
              <template slot-scope="{row}">
                <el-input v-model="row.card_name" disabled></el-input>
              </template>
            </el-table-column>
            <el-table-column label="面值" prop="card_value">
              <template slot-scope="{row}">
                <el-input v-model="row.card_value" disabled></el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡号段" width="300">
              <template slot-scope="{row}">
                <div
                  v-for="(item,index) of row.card_number_segments"
                  :key="index"
                  style="margin:5px 0;"
                >
                  <el-input
                    style="width: 100px;"
                    v-model="item.card_code_start"
                    size="mini"
                    disabled
                  />&nbsp;——
                  <el-input
                    style="width: 100px;"
                    v-model="item.card_code_end"
                    size="mini"
                    disabled
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="card_num">
              <template slot-scope="{row}">
                <el-input v-model="row.card_num" disabled></el-input>
              </template>
            </el-table-column>
            <el-table-column label="折扣率" prop="discount_ratio">
              <template slot-scope="{row}">
                <el-input style="width:100px" v-model="row.discount_ratio" disabled></el-input>%
              </template>
            </el-table-column>
            <el-table-column label="折扣金额" prop="discount_value">
              <template slot-scope="{row}">
                <el-input v-model="row.discount_value" disabled></el-input>
              </template>
            </el-table-column>
            <el-table-column label="优惠后金额" prop="after_discount_price">
              <template slot-scope="{row}">
                <el-input v-model="row.after_discount_price" disabled></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="合计金额">
              <el-input
                v-model="fundamental.amount_in_total"
                disabled
                size="medium"
                type="text"
                placeholder="请输入合计金额"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="优惠后总金额">
              <el-input
                v-model="fundamental.total_amount_after_discount"
                disabled
                size="medium"
                type="text"
                placeholder="请输入优惠后总金额"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注信息" style="width:100%">
              <el-input
                v-model="fundamental.remark"
                disabled
                size="medium"
                type="textarea"
                :rows="3"
                placeholder="请输入200字以内的备注信息"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as API_order from "@/api/order";

export default {
  name: "writeOffOpenCardDetailed",
  components: {},
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      fundamental: {},
      cardTable: [],
      type: null
    };
  },
  mounted() {
    this.params = this.$route.query;
    this.type = this.$route.query.trade_type;
    sessionStorage.setItem("activeName", this.type);
    console.log(this.type);
    API_order.getInfoUnderWrite(this.params).then(res => {
      this.cardTable = [];
      this.fundamental = res[0];
      this.fundamental.amount_in_total = 0;
      this.fundamental.total_amount_after_discount = 0;
      let cardName = "";
      res.forEach(elem => {
        this.fundamental.amount_in_total += elem.card_value * elem.card_num;
        this.fundamental.total_amount_after_discount +=
          elem.after_discount_price;
        if (cardName === elem.card_name) {
          this.cardTable[this.cardTable.length - 1].card_num += elem.card_num;
          this.cardTable[this.cardTable.length - 1].after_discount_price += elem.after_discount_price;
          this.cardTable[this.cardTable.length - 1].card_number_segments.push({
            card_code_start: elem.card_code_start,
            card_code_end: elem.card_code_end
          });
        } else {
          cardName = elem.card_name;
          elem.card_number_segments = [
            {
              card_code_start: elem.card_code_start,
              card_code_end: elem.card_code_end
            }
          ];
          this.cardTable.push(elem);
        }
      });
      console.log(this.cardTable);
    });
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  position: relative;
  border: none;
  box-shadow: none;
}
.buttonbc {
  position: absolute;
  top: 20px;
  right: 20px;
}
/deep/ .el-card__header {
  border: none;
}
/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
  color: #606266;
}
.el-input {
  width: 100%;
}
</style>
